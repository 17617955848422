import React, { FC, useState, useCallback } from 'react';
import { graphql, Link } from 'gatsby';
import classnames from 'classnames';

import { DangerouslySetInnerHtml, Container } from 'layout';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import Icon from 'common/IconCustom';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { ProductsDifferencesProps } from '@shared/types/umbraco/compositions';
import { scrollToClassName } from 'utils/browser';

import { SCROLL_SECTION } from './constants';

import './ProductsDifferences.scss';

const ProductsDifferences: FC<ProductsDifferencesProps.IProductsDifferencesDataProps> = ({
  productsDifferencesTitle,
  productsDifferencesBackground,
  bigSizeTitle,
  isFullWidthLink,
  borderedImage,
  transparentTitles,
  productsDifferencesTables,
  productsDifferencesLink,
  packshotSize,
  isLongTitle,
}) => {
  const { isDesktop } = useScreenRecognition();
  const [activeTab, setActiveTab] = useState(0);

  const onChangeTab = useCallback(
    (index: number) => () => {
      setActiveTab(index);
      scrollToClassName(SCROLL_SECTION);
    },
    []
  );

  return (
    <section
      className={classnames('products-differences', {
        [`products-differences--${productsDifferencesBackground?.[0]}`]:
          productsDifferencesBackground?.[0] && productsDifferencesBackground,
      })}
      data-testid="products-differences"
    >
      <Container fluid>
        <DangerouslySetInnerHtml
          html={productsDifferencesTitle}
          className={classnames('products-differences__header', {
            'products-differences__header--big-size': bigSizeTitle,
          })}
        />
        {productsDifferencesTables?.length > 1 ? (
          <ul className="products-differences__tabs">
            {productsDifferencesTables.map(
              ({ properties: { tabTitle, tabTitleAriaLabel } }, index) => (
                <li className="products-differences__tab" key={tabTitle}>
                  <Button
                    ariaLabel={tabTitleAriaLabel}
                    clickHandler={onChangeTab(index)}
                    variant="link"
                    className={classnames('btn products-differences__tab-button', {
                      'products-differences__tab-button--active': index === activeTab,
                    })}
                  >
                    {tabTitle}
                  </Button>
                </li>
              )
            )}
          </ul>
        ) : null}
        <div
          className={classnames('products-differences__table-scroll', {
            'products-differences__table-scroll--half-width':
              productsDifferencesTables?.[activeTab].properties.productsDifferencesColumn
                ?.length === 2,
          })}
        >
          <div
            className={classnames('products-differences__table', {
              'products-differences__table--with-border': borderedImage,
            })}
          >
            {productsDifferencesTables?.[activeTab].properties.productsDifferencesTitles?.length ? (
              <div
                className={classnames('products-differences__titles', {
                  'products-differences__titles--big-indent': borderedImage,
                  [`products-differences__titles--height-${packshotSize}`]: packshotSize,
                })}
              >
                {productsDifferencesTables[activeTab].properties.productsDifferencesTitles.map(
                  ({ properties: { icon, imageIcon, imageAlt, description } }, index) => (
                    <div
                      key={imageAlt}
                      className={classnames('products-differences__title', {
                        'products-differences__title--transparent-title': transparentTitles,
                        'products-differences__title--long-title': isLongTitle,
                        'products-differences__title--image-height':
                          productsDifferencesTables[activeTab].properties
                            .productsDifferencesColumn?.[0].properties.tableValues?.[index]
                            .properties?.tableValueImage?.length === 1,
                      })}
                    >
                      {imageIcon && imageIcon.fallbackUrl ? (
                        <GatsbyImage
                          image={imageIcon}
                          alt={imageAlt}
                          objectFit="contain"
                          className="products-differences__title-icon"
                        />
                      ) : (
                        <Icon icon={icon?.[0]} />
                      )}

                      {description ? (
                        <DangerouslySetInnerHtml
                          className="products-differences__title-description"
                          html={description}
                        />
                      ) : null}
                    </div>
                  )
                )}
              </div>
            ) : null}

            {productsDifferencesTables?.[activeTab].properties.productsDifferencesColumn?.length ? (
              <div className="products-differences__columns">
                {productsDifferencesTables[activeTab].properties.productsDifferencesColumn.map(
                  ({
                    properties: {
                      productsDifferencesActives,
                      productsDifferencesImage,
                      startGradient,
                      endGradient,
                      columnStyle,
                      tableValues,
                      productsDifferencesName,
                      productLink,
                    },
                  }) => (
                    <div
                      key={productsDifferencesImage[0].properties.image.fallbackUrl}
                      className={classnames('products-differences__column', {
                        [`products-differences__column--style-${columnStyle?.[0]}`]:
                          columnStyle?.[0],
                      })}
                      style={{
                        backgroundImage: startGradient?.value
                          ? `linear-gradient(to left, #${startGradient.value}, #${endGradient.value})`
                          : null,
                      }}
                    >
                      {isDesktop ? (
                        <div
                          className={classnames('products-differences__column-image-box', {
                            'products-differences__column-image-box--with-border': borderedImage,
                            [`products-differences__column-image-box--height-${packshotSize}`]:
                              packshotSize,
                          })}
                        >
                          <div
                            className={classnames('products-differences__column-image-wrapper', {
                              [`products-differences__column-image-wrapper--style-${packshotSize}`]:
                                packshotSize,
                            })}
                          >
                            {productsDifferencesImage?.length ? (
                              <GatsbyImage
                                image={productsDifferencesImage[0].properties.image}
                                alt={productsDifferencesImage[0].properties.imageAlt}
                                objectFit="contain"
                                className="products-differences__column-image"
                              />
                            ) : null}
                            {productsDifferencesName ? (
                              <strong className="products-differences__column-name">
                                {productsDifferencesName}
                              </strong>
                            ) : null}
                            {productLink?.length ? (
                              <Link
                                to={productLink[0].properties?.link?.[0]?.url}
                                aria-label={productLink[0]?.properties?.ariaLabel}
                                className="products-differences__column-link"
                              >
                                {productLink[0].properties?.link?.[0]?.name}
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {tableValues?.length
                        ? tableValues.map(
                            (
                              {
                                properties: {
                                  tableValueImage,
                                  tableValueDots,
                                  tableValueDotsLabel,
                                },
                              },
                              index
                            ) => (
                              <div
                                className={classnames('products-differences__column-value', {
                                  'products-differences__column-value--image-value':
                                    tableValueImage?.length,
                                })}
                                key={`${productsDifferencesName}${index}`}
                              >
                                {tableValueImage?.length ? (
                                  <GatsbyImage
                                    image={tableValueImage[0].properties.image}
                                    alt={tableValueImage[0].properties.imageAlt}
                                    objectFit="contain"
                                    className="products-differences__column-value-image"
                                  />
                                ) : (
                                  <>
                                    <span className="visual-hidden">{tableValueDotsLabel}</span>
                                    {Array.from({ length: tableValueDots }, () => (
                                      <span className="products-differences__column-dot" />
                                    ))}
                                  </>
                                )}
                              </div>
                            )
                          )
                        : null}

                      {Array.from({ length: productsDifferencesActives }, (_, i: number) => (
                        <DangerouslySetInnerHtml
                          className={classnames(
                            'products-differences__column-icon',
                            `products-differences__column-icon--${startGradient.label}`
                          )}
                          key={
                            productsDifferencesTables[activeTab].properties
                              .productsDifferencesTitles?.[i]?.properties.description
                          }
                          html={
                            productsDifferencesTables[activeTab].properties
                              .productsDifferencesTitles?.[i]?.properties.description
                          }
                        />
                      ))}
                    </div>
                  )
                )}
              </div>
            ) : null}

            {!isDesktop &&
            productsDifferencesTables?.[activeTab].properties.productsDifferencesColumn?.length ? (
              <div
                className={classnames('products-differences__packshots', {
                  'products-differences__packshots--long-title': isLongTitle,
                })}
              >
                {productsDifferencesTables[activeTab].properties.productsDifferencesColumn.map(
                  ({
                    properties: {
                      productsDifferencesImage,
                      startGradient,
                      endGradient,
                      columnStyle,
                      productsDifferencesName,
                      productLink,
                    },
                  }) =>
                    productsDifferencesImage?.length ? (
                      <div
                        className={classnames('products-differences__packshot', {
                          [`products-differences__packshot--style-${columnStyle?.[0]}`]:
                            columnStyle?.[0],
                          'products-differences__packshot--with-border': borderedImage,
                        })}
                        style={{
                          backgroundImage: startGradient?.value
                            ? `linear-gradient(to left, #${startGradient.value}, #${endGradient.value})`
                            : null,
                        }}
                      >
                        <div className="products-differences__packshot-wrapper">
                          <GatsbyImage
                            image={productsDifferencesImage[0].properties.image}
                            alt={productsDifferencesImage[0].properties.imageAlt}
                            objectFit="contain"
                            className="products-differences__packshot-image"
                          />
                          {productsDifferencesName ? (
                            <strong className="products-differences__column-name">
                              {productsDifferencesName}
                            </strong>
                          ) : null}
                          {productLink?.length ? (
                            <Link
                              to={productLink[0].properties?.link?.[0]?.url}
                              aria-label={productLink[0]?.properties?.ariaLabel}
                              className="products-differences__column-link"
                            >
                              {productLink[0].properties?.link?.[0]?.name}
                            </Link>
                          ) : null}
                        </div>
                      </div>
                    ) : null
                )}
              </div>
            ) : null}
          </div>

          <DangerouslySetInnerHtml
            html={productsDifferencesTables?.[activeTab].properties.legend}
            className="products-differences__legend"
          />
        </div>

        {productsDifferencesLink?.length ? (
          <Button
            variant="white"
            classes={classnames('products-differences__link', {
              'products-differences__link--full-width': isFullWidthLink,
            })}
            to={productsDifferencesLink?.[0].properties.link[0].url}
            ariaLabel={productsDifferencesLink?.[0].properties.ariaLabel}
          >
            {productsDifferencesLink?.[0].properties.link[0].name}
          </Button>
        ) : null}
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentProductsDifferencesProperties on TProductsDifferencesProperties {
    productsDifferencesTitle
    productsDifferencesBackground
    packshotSize
    productsDifferencesLink {
      properties {
        ariaLabel
        link {
          name
          target
          url
        }
      }
    }
    bigSizeTitle
    borderedImage
    transparentTitles
    isFullWidthLink
    isLongTitle
    productsDifferencesTables {
      properties {
        tabTitle
        tabTitleAriaLabel
        productsDifferencesTitles {
          properties {
            description
            icon
            iconAlignment
            imageIcon {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fixed(height: 36) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            imageAlt
            iconAlignment
          }
        }
        productsDifferencesColumn {
          properties {
            productsDifferencesImage {
              properties {
                image {
                  ...FragmentGatsbyProps
                  gatsbyImage {
                    childImageSharp {
                      fluid(maxWidth: 160) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                imageAlt
              }
            }
            productsDifferencesName
            productLink {
              properties {
                ariaLabel
                link {
                  name
                  target
                  url
                }
              }
            }
            productsDifferencesActives
            startGradient {
              value
              label
            }
            endGradient {
              value
            }
            columnStyle
            tableValues {
              properties {
                tableValueImage {
                  properties {
                    image {
                      ...FragmentGatsbyProps
                      gatsbyImage {
                        childImageSharp {
                          fluid(maxWidth: 72) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    imageAlt
                  }
                }
                tableValueDots
                tableValueDotsLabel
              }
            }
          }
        }
        legend
      }
    }
  }
  fragment FragmentProductsDifferences on TProductsDifferencesData {
    structure
    properties {
      ... on TProductsDifferencesProperties {
        ...FragmentProductsDifferencesProperties
      }
    }
  }
`;

export default ProductsDifferences;
