import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import StepList from 'components/StepList';
import { Container } from 'layout';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import Video from 'common/Video';
import Button from 'common/Button';
import PageSchema from 'common/PageSchema';

import { HowToUseTypes } from '@shared/types/umbraco/compositions';
import './HowToUse.scss';

const HowToUse: FC<HowToUseTypes.IHowToUse> = ({ properties }) => {
  const { image, imageAlt, youtubeLink, vimeoLink } = properties.leftBlock?.[0]?.properties || {};
  const video = youtubeLink || vimeoLink;
  const btn = properties?.button?.[0]?.properties;
  const withMedia = !!video || !!image?.fallbackUrl;
  const fullWidth = withMedia && properties?.steps?.length;
  const { isSchemaEnable, siteSettings } = properties;

  return (
    <Container className={classNames('how-to-use', {})}>
      {!!properties?.steps?.length && isSchemaEnable && (
        <PageSchema
          siteSettings={siteSettings}
          additionalData={{
            title: properties.text,
            steps: properties?.steps,
            image,
          }}
          type="HowTo"
        />
      )}
      {properties.text ? (
        <DangerouslySetInnerHtml html={properties.text} className="how-to-use__text" />
      ) : null}
      <div
        className={classNames('how-to-use__content', {
          'how-to-use__content--small': !fullWidth,
        })}
      >
        {withMedia ? (
          <div
            className={classNames('how-to-use__media', {
              'how-to-use__media--image': !!image?.fallbackUrl,
            })}
          >
            {image?.fallbackUrl ? <GatsbyImage image={image} alt={imageAlt} /> : null}
            {video ? <Video {...properties.leftBlock?.[0].properties} /> : null}
          </div>
        ) : null}

        {properties?.steps?.length ? (
          <div className="how-to-use__steps">
            <StepList properties={properties} />
            {btn?.link?.[0]?.url ? (
              <Button
                to={btn.link[0].url}
                key={btn.link[0].name}
                variant="secondary-link"
                iconSuffix="chevron-right"
                ariaLabel={btn.ariaLabel}
                classes="how-to-button"
              >
                {btn.link[0].name}
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    </Container>
  );
};

export default HowToUse;

export const query = graphql`
  fragment FragmentHowToUse on THowToUse {
    structure
    properties {
      text
      steps {
        properties {
          description
          imageAutoHeight
          link {
            properties {
              ariaLabel
              link {
                name
                target
                url
              }
            }
          }
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 560) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
      leftBlock {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 580) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
          youtubeLink
          vimeoLink
          btnAriaLabel
          time
          description
          previewImage {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
      showNumber
      button {
        properties {
          ariaLabel
          link {
            name
            url
          }
        }
      }
      showInMenu
      anchorName
      anchorTitle
      isSchemaEnable
    }
  }
`;
