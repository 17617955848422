import React, { FC, useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import './AddToCartComponent.scss';
import useShopify from 'hooks/useShopify';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import QuantitySelector from 'common/QuantitySelector';
import Button from 'common/Button';
import { AddToCartComponentProps } from './model';
import gtmCustomEvents from '../../utils/gtmCustomEvents';

const AddToCartComponent: FC<AddToCartComponentProps> = ({
  skuId,
  shopifyId,
  theme = 'dark',
  cartProductsData,
  isShopify,
  lang,
  productTitle,
}) => {
  const { isLoading, purchaseSettings } = useContext(CartContext);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [orderQuantity, setOrderQuantity] = useState<number>(1);
  const { checkoutAndAddProduct, productInfo, addButtonText, discountString, btnLabel } =
    useShopify({
      isShopify,
      lang,
      skuId,
      shopifyId,
      cartProductsData,
      productTitle,
    });

  const { quantitySelectorAriaLabel, oldPriceLabel, currentPriceLabel } = purchaseSettings;

  useEffect(() => {
    if (JSON.stringify(productInfo) !== '{}') {
      const { id, quantityAvailable, price } = productInfo;
      gtmCustomEvents(
        {
          detail: {
            actionField: { list: 'Product Page' },
            products: [
              {
                id,
                price,
                name: productTitle,
                quantity: quantityAvailable,
                brand: 'Finish',
                category: 'Bundles',
              },
            ],
          },
        },
        'eec.detail'
      );
    }
  }, [JSON.stringify(productInfo)]);

  const addProductToCheckout = () => {
    setIsButtonLoading(true);

    checkoutAndAddProduct(orderQuantity).then(() => setIsButtonLoading(false));
  };

  const { compareAtPrice, currencieLabel, price, quantityAvailable } = productInfo || {};

  return Object.keys(productInfo).length ? (
    <div
      className={classnames('add-to-cart', `add-to-cart--${theme}-theme`)}
      data-testid="add-to-cart"
    >
      <div className="add-to-cart__price-container">
        <div className="price-container__price">
          {!!compareAtPrice && (
            <div className="price__old-price">
              <span className="sr-only">{oldPriceLabel}</span>
              {currencieLabel}
              {compareAtPrice}
            </div>
          )}
          {!!price && (
            <div className="price__new-price">
              <span className="sr-only">{currentPriceLabel}</span>
              {currencieLabel}
              {price}
            </div>
          )}
        </div>
        {!!discountString && <div className="price-container__discount">{discountString}</div>}
      </div>
      <div className="add-to-cart__billing-info">
        <div className="billing-info__quantity">
          <QuantitySelector
            decreaseHandler={() => setOrderQuantity(orderQuantity - 1)}
            increaseHandler={() => setOrderQuantity(orderQuantity + 1)}
            orderQuantity={orderQuantity}
            availableProductQuantity={quantityAvailable}
            quantitySelectorAriaLabel={quantitySelectorAriaLabel}
          />
        </div>
        <Button
          isLoading={isButtonLoading}
          disabled={quantityAvailable === 0 || isLoading}
          variant="primary"
          clickHandler={addProductToCheckout}
          classes="billing-info__add-button"
          iconPrefix="cart"
          ariaLabel={btnLabel}
        >
          {addButtonText}
        </Button>
      </div>
    </div>
  ) : null;
};

export default AddToCartComponent;

export const query = graphql`
  fragment FragmentPurchaseSettings on TPurchaseSettings {
    addToCartTitle
    addToCartLabel
    soldOutTitle
    soldOutLabel
    discountPlaceHolder
    addToCartMessage
    checkoutButtonLabel
    cartQuantityLabel
    cartPriceLabel
    cartRemoveLabel
    totalTitle
    itemsInBundlePlaceholder
    itemsInCartTitle
    availabilityMessage
    deletedItemPlaceholder
    continueShoppingPath {
      properties {
        ariaLabel
        link {
          name
          url
        }
      }
    }
    addDeletedItemTitle
    quantitySelectorAriaLabel
    saveLabel
    oldPriceLabel
    currentPriceLabel
    currentPriceLabelProduct
    productCardVariantsLabel
    productPricePlaceholderSingle
    productPricePlaceholder
    bundlePricePlaceholder
  }
`;
