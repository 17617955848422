import React, { useEffect, FC } from 'react';
import { graphql } from 'gatsby';
import { DangerouslySetInnerHtml } from 'layout';
import classNames from 'classnames';

import Icon from 'common/IconCustom';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import { ProductTabsProps } from '@shared/types';

import './ProductTabs.scss';

const ProductTabs: FC<ProductTabsProps.IProductTabsProps> = ({
  productTabsTitle,
  tabs,
  tabContent,
  activeTab,
  setActiveTab,
  sectionTheme,
}) => {
  useEffect(() => {
    setActiveTab(activeTab || tabs?.[0].queryString);
  }, []);
  const handleChange = (queryString: string) => setActiveTab(queryString);

  return (
    <section
      className={classNames('product-tabs', {
        [`product-tabs--${sectionTheme}`]: sectionTheme,
      })}
    >
      {productTabsTitle ? <h2 className="sr-only">{productTabsTitle}</h2> : null}

      {tabs?.length ? (
        <ul className="product-tabs__links">
          {tabs?.map((tab) => (
            <li className="product-tabs__link-item" key={tab.queryString}>
              <button
                onClick={() => handleChange(tab.queryString)}
                className={classNames('product-tabs__link', {
                  active: tab.queryString === activeTab,
                })}
                type="button"
              >
                {tab.name}
              </button>
            </li>
          ))}
        </ul>
      ) : null}

      {tabs?.length ? (
        <div className="product-tabs__content">
          {tabContent?.map((content) =>
            content.properties.tabId === activeTab ? (
              <div
                className={`product-tabs__tab product-tabs__tab--${content.properties.tabStyle[0]}`}
                key={content.properties.tabId}
              >
                {content.properties.column?.map((contentItem) => (
                  <div
                    className="product-tabs__column"
                    key={contentItem.properties.contentItem[0].properties.text}
                  >
                    <DangerouslySetInnerHtml
                      html={contentItem.properties.title}
                      className="product-tabs__column-tab-title"
                    />
                    {contentItem.properties.contentItem?.map((item) => (
                      <div className="product-tabs__item" key={item.properties.title}>
                        {item.properties.title ? (
                          <h3 className="product-tabs__column-title">
                            {item.properties.icon?.length ? (
                              <Icon icon={item.properties.icon?.[0]} />
                            ) : null}
                            {item.properties.title}
                          </h3>
                        ) : null}
                        <div className="product-tabs__column-row">
                          <DangerouslySetInnerHtml
                            html={item.properties.text}
                            className="product-tabs__column-text"
                          />
                          <GatsbyImage
                            image={item.properties.image}
                            alt={item.properties.imageAlt}
                            className="product-tabs__column-image"
                          />
                          {item.properties.link?.[0] ? (
                            <Button
                              to={item.properties.link[0].url}
                              ariaLabel={item.properties.link[0].name}
                              target={item.properties.link[0].target}
                              className="product-tabs__column-link"
                              variant="primary-link"
                            >
                              {item.properties.link[0].name}
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : null
          )}
        </div>
      ) : null}
    </section>
  );
};

export const query = graphql`
  fragment FragmentProductTabs on TProductTabs {
    properties {
      showInMenu
      anchorName
      anchorTitle
      tabs {
        name
        queryString
      }
      tabContent {
        structure
        properties {
          tabId
          tabStyle
          column {
            properties {
              title
              contentItem {
                properties {
                  title
                  text
                  imageAlt
                  link {
                    name
                    url
                    target
                  }
                  image {
                    ...FragmentGatsbyProps
                    gatsbyImage {
                      childImageSharp {
                        fluid(maxWidth: 80, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  icon
                }
                structure
              }
            }
            structure
          }
        }
      }
      sectionTheme
    }
    structure
  }
`;

export default ProductTabs;
