import React, { FC } from 'react';
import Slider from 'react-slick';
import 'styles/common/slick-styles.scss';
import classnames from 'classnames';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import { IPDCarouselProps } from './models';
import PrevArrow from './components/prevArrow';
import NextArrow from './components/nextArrow';
import './PDCarousel.scss';

const PDCarousel: FC<IPDCarouselProps> = ({ slides, classes = '', ariaNext, ariaPrev }) => {
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    prevArrow: <PrevArrow ariaLabel={ariaPrev} />,
    nextArrow: <NextArrow ariaLabel={ariaNext} />,
    customPaging: (i) => (
      <Button ariaLabel={`Go to slide ${i + 1}`} variant="icon">
        {i + 1}
      </Button>
    ),
  };

  return slides?.length ? (
    <div className={classnames('carousel', classes)} data-testid="carousel">
      <Slider {...settings}>
        {slides?.map(({ properties: { imageAlt, image } }, index) => (
          <div key={imageAlt} className="carousel__slide">
            <GatsbyImage
              image={image}
              alt={imageAlt}
              objectFit="contain"
              className="carousel__slide-img"
              isLazyLoading={index !== 0}
              fadeIn={false}
            />
          </div>
        ))}
      </Slider>
    </div>
  ) : null;
};

export default PDCarousel;
