import { useState, useEffect, useMemo } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { UseVariantsHook } from './model';

const useVariants: UseVariantsHook = (productVariants, productSize, productScent) => {
  const location = useLocation();
  const [selected, setSelected] = useState({
    size: '',
    scent: '',
  });

  const isDeadLock = useMemo(() => {
    const scent = {};

    productVariants.nodes.forEach((variant) => {
      const { productSize: productSizeVal = '', productScent: productScentVal = '' } = variant;

      scent[productScentVal] = {
        ...scent[productScentVal],
        [productSizeVal]: productSizeVal,
      };
    });

    if (Object.keys(scent).length <= 1) {
      return false;
    }

    const checkArr = Object.keys(scent).map((scentKey) => {
      let isDead = true;
      Object.keys(scent[scentKey]).forEach((scentValue) => {
        const copyScents = { ...scent };
        delete copyScents[scentKey];

        Object.values(copyScents).forEach((scentObj) => {
          if (scentValue in scentObj) {
            isDead = false;
          }
        });
      });

      return isDead;
    });

    return checkArr.some(Boolean);
  }, [JSON.stringify(productVariants)]);

  useEffect(() => {
    if (isDeadLock) {
      setSelected({ size: `${productSize} ${productScent}`, scent: productScent });
    } else {
      setSelected({ size: productSize, scent: productScent });
    }
  }, [productSize, productScent, isDeadLock]);

  const sizes = useMemo(() => {
    const result = {};
    productVariants?.nodes?.forEach((variant) => {
      if (!variant.productSize) return;
      if (isDeadLock) {
        result[`${variant.productSize} ${variant.productScent}`] = {
          ...{},
          ...result[`${variant.productSize} ${variant.productScent}`],
          [`${variant.productSize} ${variant.productScent}`]: variant.link,
        };
      } else {
        result[variant.productSize] = {
          ...{},
          ...result[variant.productSize],
          [variant.productScent]: variant.link,
        };
      }
    });

    return result;
  }, [productVariants]);

  const scents = useMemo(() => {
    const result = {};
    if (isDeadLock) return result;
    productVariants?.nodes?.forEach((variant) => {
      if (!variant.productScent) return;
      result[variant.productScent] = {
        ...{},
        ...result[variant.productScent],
        [variant.productSize]: variant.link,
      };
    });

    return result;
  }, [productVariants]);
  const clickHandler = (label, type) => {
    setSelected((oldState) => ({
      ...oldState,
      [type]: label,
    }));
  };

  useEffect(() => {
    let link;
    if (selected.size && !Object.keys(scents).length) {
      link = sizes?.[selected.size]?.[selected.scent] || sizes?.[selected.size]?.null;
    }
    if (selected.scent && !Object.keys(sizes).length) {
      link = scents?.[selected.scent]?.[selected.size] || scents?.[selected.scent]?.null;
    }
    if (selected.size && selected.scent) {
      link = scents?.[selected.scent]?.[selected.size];
    }
    if (isDeadLock && selected.size) {
      link = sizes?.[selected.size]?.[selected.size];
    }

    if (link && link !== location?.pathname) {
      navigate(link);
    }
  }, [selected]);

  return {
    selected,
    sizes,
    scents,
    isDeadLock,
    clickHandler,
  };
};

export default useVariants;
