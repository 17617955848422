import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import useMatchMedia from 'hooks/useMatchMedia';

import { IImagesBannerProps } from './model';

import './ImagesBanner.scss';

const ImagesBanner: FC<IImagesBannerProps> = ({
  leftSlotImage,
  rightSlotImage,
  background,
  mobileBackground,
  footnote,
}) => {
  const isDesktop = useMatchMedia('(min-width: 768px)');

  const backgroundImage = isDesktop
    ? background?.[0]?.properties
    : mobileBackground?.[0]?.properties;

  return (
    <section className="images-banner">
      <div className="images-banner__container">
        <div className="images-banner__left-slot">
          <GatsbyImage
            className="images-banner__left-slot--image"
            image={leftSlotImage?.[0].properties.image}
            alt={leftSlotImage?.[0].properties.imageAlt}
            objectFit="contain"
          />
        </div>
        <div className="images-banner__right-slot">
          <GatsbyImage
            className="images-banner__right-slot--image"
            image={rightSlotImage?.[0].properties.image}
            alt={rightSlotImage?.[0].properties.imageAlt}
            objectFit="contain"
          />
        </div>
        <DangerouslySetInnerHtml html={footnote} className="images-banner__footnote" />
      </div>
      <div className="images-banner__background">
        <GatsbyImage image={backgroundImage.image} alt={backgroundImage.imageAlt} />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentImagesBanner on TImagesBanner {
    properties {
      leftSlotImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      rightSlotImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      background {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 992, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileBackground {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      footnote
    }
    structure
  }
`;
export default ImagesBanner;
