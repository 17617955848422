import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import { ProductPageTypes } from '@shared/types/umbraco/content';

import './JetDryBanner.scss';

const JetDryBanner: FC<ProductPageTypes.IJetDryBanner> = ({
  title,
  description,
  backgroundImage,
}) => (
  <div className="jet-dry-banner" data-testid="jet-dry-banner">
    <Container fluid>
      {backgroundImage?.[0].properties?.image ? (
        <div className="jet-dry-banner__background">
          <GatsbyImage
            image={backgroundImage[0].properties.image}
            alt={backgroundImage[0].properties.imageAlt}
            objectFit="contain"
            isLazyLoading={false}
            fadeIn={false}
            useFocalPoint
          />
        </div>
      ) : null}
      <DangerouslySetInnerHtml className="jet-dry-banner__title" html={title} />
      <DangerouslySetInnerHtml className="jet-dry-banner__description" html={description} />
    </Container>
  </div>
);

export const query = graphql`
  fragment FragmentJetDryBanner on TJetDryBanner {
    properties {
      description
      title
      backgroundImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default JetDryBanner;
