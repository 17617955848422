import React, { FC } from 'react';
import Button from 'common/Button';
import classNames from 'classnames';
import { IProductVariant } from '../models';

const ProductVariant: FC<IProductVariant> = ({
  label,
  onSelected,
  type,
  current,
  availableScents,
  availableType,
  isDeadLock,
}) => {
  const clickHandler = () => {
    onSelected && onSelected(label, type);
  };
  const disabled = isDeadLock
    ? false
    : Boolean(current[availableType]) && !availableScents?.[current[availableType]];

  return (
    <Button
      classes={classNames({ active: current[type] === label })}
      clickHandler={clickHandler}
      ariaLabel={label}
      variant="secondary"
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default ProductVariant;
