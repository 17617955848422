import React, { FC } from 'react';
import classNames from 'classnames';
import { BazaarRatingSummary } from '@phx-husky/bazaarvoice-components';
import { ProductPageTypes } from '@shared/types/umbraco/content/productPage';

import { Container, DangerouslySetInnerHtml } from 'layout';
import BreadCrumbs from 'common/BreadCrumbs';
import WTBCards from 'components/WTBCards';
import PDCarouselDouble from 'components/PDCarouselDouble';
import ProductVariants from 'components/ProductVariants';

import useScreenRecognition from 'hooks/useScreenRecognition';
import getFamily from 'utils/getFamily';
import transformProductSubtitle from 'utils/stringUtils/transformProductSubtitle';

import './ProductDetailsAlternative.scss';

const ProductDetailsAlternative: FC<ProductPageTypes.IProductDetails> = ({
  title,
  shortDescription,
  tags,
  breadCrumbs,
  availableLabel,
  availableLinks,
  retailersToShow,
  retailersToShowMobile,
  ctaLabelLess,
  ctaAriaLabelLess,
  ctaLabel,
  ctaAriaLabel,
  images,
  productVariants,
  scentLabel,
  sizeLabel,
  productSize,
  productDescriptionTitle,
  productScent,
  packagingType,
  productsList,
  waiSettings,
  lang,
  masterProduct,
  subtitlePlaceholder = '',
  bvScript,
  bvProductId,
  sizesOpener,
  sizesOpenerAria,
  scentsOpener,
  scentsOpenerAria,
}) => {
  const family = getFamily(tags);
  const bcTheme = 'alternative-black';
  const { isDesktop } = useScreenRecognition();

  const BundleProductsListElement = () =>
    productsList?.length ? (
      <ul className="product-details-alternative__products-list">
        {productsList.map((el) => (
          <li key={el}>
            <bdi>{el}</bdi>
          </li>
        ))}
      </ul>
    ) : null;

  const subtitle = transformProductSubtitle({
    subtitlePlaceholder,
    productSize,
    packagingType,
    productScent,
  });

  return (
    <div
      className={classNames('product-details-alternative', {
        [`product-details-alternative--${family?.color?.label}-family`]: Boolean(family),
      })}
      data-testid="product-details"
    >
      <Container fluid>
        {!!breadCrumbs?.length && (
          <BreadCrumbs
            data={breadCrumbs}
            popIndex={masterProduct?.length ? 2 : 1}
            theme={bcTheme}
          />
        )}

        <div className="product-details-alternative__grid">
          <div className="product-details-alternative__text">
            {title ? (
              <h1 className="product-details-alternative__title">
                <DangerouslySetInnerHtml element="span" html={title} />
                {subtitle && (
                  <span className="product-details-alternative__title--sub">{subtitle}</span>
                )}
              </h1>
            ) : null}
            {isDesktop ? <BundleProductsListElement /> : null}
            {bvScript ? <BazaarRatingSummary productId={bvProductId} /> : null}
          </div>
          <div
            className={classNames(
              'product-details-alternative__controls',
              `product-details-alternative__controls--${bcTheme}-theme`
            )}
          >
            {shortDescription ? (
              <div className="product-details-alternative__overwiew">
                <strong className="product-details-alternative__subtitle">
                  {productDescriptionTitle}
                </strong>
                <DangerouslySetInnerHtml
                  className="product-details-alternative__description"
                  html={shortDescription}
                />
              </div>
            ) : null}
            {!isDesktop ? <BundleProductsListElement /> : null}
            {!productsList?.length && productVariants?.nodes?.length ? (
              <ProductVariants
                productVariants={productVariants}
                productSize={productSize}
                productScent={productScent}
                scentLabel={scentLabel}
                sizeLabel={sizeLabel}
                lang={lang}
                isAlternativeStyle
                sizesOpener={sizesOpener}
                sizesOpenerAria={sizesOpenerAria}
                scentsOpener={scentsOpener}
                scentsOpenerAria={scentsOpenerAria}
              />
            ) : null}

            {availableLinks?.length ? (
              <WTBCards
                availableLabel={availableLabel}
                availableLinks={availableLinks}
                retailersToShowDesctop={retailersToShow}
                retailersToShowMobile={retailersToShowMobile}
                ctaLabelLess={ctaLabelLess}
                ctaAriaLabelLess={ctaAriaLabelLess}
                ctaLabel={ctaLabel}
                ctaAriaLabel={ctaAriaLabel}
                isAlternative
              />
            ) : null}
          </div>
          <div
            className={classNames(
              'product-details-alternative__media',
              `product-details-alternative__media--${bcTheme}-theme`
            )}
          >
            {images?.length ? (
              <PDCarouselDouble
                classes="product-details-alternative__carousel"
                slides={images}
                ariaNext={waiSettings?.ariaNext}
                ariaPrev={waiSettings?.ariaPrev}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProductDetailsAlternative;
