import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { Container, DangerouslySetInnerHtml } from 'layout';
import { UmbracoProductBenefits } from '@shared/types/umbraco/compositions';
import Icon from 'common/IconCustom';
import GatsbyImage from 'common/GatsbyImage';
import getFamily from 'utils/getFamily';
import './ProductBenefits.scss';

const ProductBenefits: FC<UmbracoProductBenefits.IData> = ({
  title,
  titleRte,
  items,
  tags,
  isFiveInRow,
  benefitsTheme,
}) => {
  const family = getFamily(tags);

  return (
    <div
      className={classNames('product-benefits', {
        [`product-benefits--${family?.color?.label}-family`]: Boolean(family),
        [`product-benefits--long-row`]: isFiveInRow,
        [`product-benefits--${benefitsTheme}`]: benefitsTheme,
      })}
      data-testid="product-benefits"
    >
      <Container fluid>
        <DangerouslySetInnerHtml element="h2" className="product-benefits__title" html={title} />
        <DangerouslySetInnerHtml className="product-benefits__title-rte" html={titleRte} />
        <div
          className={classNames('product-benefits__grid', {
            [`product-benefits--long-row`]: isFiveInRow,
          })}
        >
          {items?.length
            ? items.map(
                (
                  {
                    properties: {
                      icon,
                      imageIcon,
                      imageIconLarge,
                      imageAlt,
                      description,
                      iconClass,
                    },
                  },
                  idx
                ) => (
                  <div
                    key={description.length + idx}
                    className={classNames('product-benefits__item', {
                      [`product-benefits__item--${iconClass}`]: iconClass,
                    })}
                  >
                    {imageIcon && imageIcon.fallbackUrl ? (
                      <GatsbyImage
                        image={imageIcon}
                        alt={imageAlt}
                        objectFit="contain"
                        className="product-benefits__item-icon"
                      />
                    ) : (
                      <Icon icon={icon?.[0]} />
                    )}
                    {imageIconLarge && imageIconLarge.fallbackUrl ? (
                      <GatsbyImage
                        image={imageIconLarge}
                        alt={imageAlt}
                        objectFit="contain"
                        className="product-benefits__item-icon"
                      />
                    ) : null}
                    {description ? (
                      <DangerouslySetInnerHtml
                        className="product-benefits__description"
                        html={description}
                      />
                    ) : null}
                  </div>
                )
              )
            : null}
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentBenefits on TBenefits {
    properties {
      showInMenu
      anchorName
      anchorTitle
      title
      titleRte
      isFiveInRow
      benefitsTheme
      items {
        properties {
          description
          icon
          imageIcon {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fixed(width: 64) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          imageIconLarge {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
          iconAlignment
          iconClass
        }
      }
    }
    structure
  }
`;

export default ProductBenefits;
