import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, DangerouslySetInnerHtml } from 'layout';
import { UmbracoProductOverview } from '@shared/types/umbraco/compositions';
import './ProductOverview.scss';

const ProductOverview: FC<UmbracoProductOverview.IData> = ({ description, title }) => (
  <div className="product-overview" data-testid="product-overview">
    <Container fluid>
      <div className="product-overview__grid">
        {title ? (
          <DangerouslySetInnerHtml element="h2" className="product-overview__title" html={title} />
        ) : null}
        {description ? (
          <DangerouslySetInnerHtml className="product-overview__description" html={description} />
        ) : null}
      </div>
    </Container>
  </div>
);

export const query = graphql`
  fragment FragmentOverview on TOverview {
    properties {
      title
      description
      showInMenu
      anchorName
      anchorTitle
    }
    structure
  }
`;

export default ProductOverview;
