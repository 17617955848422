import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { ITextColumnProps } from './model';
import './BrandsBanner.scss';

const BrandsBannerColumn: FC<ITextColumnProps> = ({
  topText,
  middleText,
  bottomText,
}) => (
  <div className="brands-banner__content-column">
    <DangerouslySetInnerHtml className="brands-banner__content-text" html={topText} />
    <DangerouslySetInnerHtml className="brands-banner__content-middle-text" html={middleText} />
    <DangerouslySetInnerHtml className="brands-banner__content-text" html={bottomText} />
  </div>
);

export default BrandsBannerColumn;
