import React, { FC } from 'react';
import PageSchema from 'common/PageSchema';
import transformProductTitle from 'utils/stringUtils/transformProductTitle';
import useShopify from 'hooks/useShopify';
import { IProductPageSchemaWrapperProps } from './models';

const ProductPageSchemaWrapper: FC<IProductPageSchemaWrapperProps> = ({
  brandSettings,
  title,
  productSize,
  packagingType,
  productScent,
  images,
  shortDescription,
  link,
  isShopify,
  lang,
  skuId,
  shopifyId,
  siteSettings,
}) => {
  const { productInfo } = useShopify({
    isShopify,
    lang,
    skuId,
    shopifyId,
  });
  const { price, quantityAvailable, priceV2 } = productInfo || {};

  const titleToShow = transformProductTitle({
    productTitle: title,
    productSize,
    packagingType,
    productScent,
    withBracket: false,
  });

  return (
    <PageSchema
      brandSettings={brandSettings}
      siteSettings={siteSettings}
      additionalData={{
        title: titleToShow,
        image: images?.[0]?.properties?.image,
        description: shortDescription || '',
        sku: skuId,
        price,
        quantityAvailable,
        priceCurrency: priceV2?.currencyCode,
      }}
      type="Product"
      path={link}
    />
  );
};

export default ProductPageSchemaWrapper;
