const settings = {
  settingsMain: {
    slidesToShow: 1,
    accessibility: true,
    arrows: false,
  },
  settingsSecondary: {
    focusOnSelect: true,
    accessibility: true,
    vertical: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          variableWidth: true,
          arrows: false,
          vertical: false,
          verticalSwiping: false,
        },
      },
    ],
  },
};

export default settings;
