import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IBrandsBannerProps } from './model';
import './BrandsBanner.scss';
import BrandsBannerColumn from './BrandsBannerColumn';

const BrandsBanner: FC<IBrandsBannerProps> = ({
  hiddenTitle,
  textBox,
  productImage,
  logosTitle,
  logosList,
}) =>
  textBox || productImage || logosList?.length ? (
    <div className="brands-banner" data-testid="brands-banner">
      {hiddenTitle ? <h2 className="sr-only">{hiddenTitle}</h2> : null}
      <Container fluid className="brands-banner__container">
        {textBox ? (
          <div className="brands-banner__content">
            <BrandsBannerColumn
              topText={textBox.properties.topLeftText}
              middleText={textBox.properties.middleLeftText}
              bottomText={textBox.properties.bottomLeftText}
            />
            <span className="brands-banner__content-symbol">{textBox.properties.centerText}</span>
            <BrandsBannerColumn
              topText={textBox.properties.topRightText}
              middleText={textBox.properties.middleRightText}
              bottomText={textBox.properties.bottomRightText}
            />
          </div>
        ) : null}

        {productImage ? (
          <GatsbyImage
            image={productImage.properties.image}
            alt={productImage?.properties.imageAlt}
            objectFit="contain"
            className="brands-banner__product-image"
          />
        ) : null}

        {logosList?.length ? (
          <div className="brands-banner__logos">
            <DangerouslySetInnerHtml className="brands-banner__logos-title" html={logosTitle} />

            <ul className="brands-banner__logos-list">
              {logosList.map(({ properties: { imageAlt, image } }) => (
                <li className="brands-banner__logo-list-item" key={imageAlt}>
                  <GatsbyImage
                    image={image}
                    alt={imageAlt}
                    objectFit="contain"
                    className="brands-banner__logo-list-item-image"
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </Container>
    </div>
  ) : null;

export const query = graphql`
  fragment FragmentBrandsBanner on TBrandsBanner {
    properties {
      hiddenTitle
      textBox {
        properties {
          topLeftText
          middleLeftText
          bottomLeftText
          centerText
          topRightText
          middleRightText
          bottomRightText
        }
      }
      productImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      logosTitle
      logosList {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default BrandsBanner;
