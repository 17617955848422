import React, { FC, useState } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';

import 'styles/common/slick-styles.scss';
import GatsbyImage from 'common/GatsbyImage';

import PrevArrow from './components/prevArrow';
import NextArrow from './components/nextArrow';
import { IPDCarouselDoubleProps } from './models';
import settings from './constants';

import './PDCarouselDouble.scss';

const PDCarouselDouble: FC<IPDCarouselDoubleProps> = ({
  slides,
  classes = '',
  ariaNext,
  ariaPrev,
}) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const sliderSettings = {
    slidesToShow: slides.length > 6 ? 5 : 6,
    arrows: slides.length > 6,
    prevArrow: <PrevArrow ariaLabel={ariaPrev} />,
    nextArrow: <NextArrow ariaLabel={ariaNext} />,
    infinite: slides.length > 6,
  };

  return slides?.length ? (
    <div className={classnames('carousel-double', classes)} data-testid="carousel-double">
      <Slider
        {...settings.settingsMain}
        asNavFor={nav2}
        ref={(slider1) => setNav1(slider1)}
        className="carousel-double__main"
      >
        {slides?.map(({ properties: { imageAlt, image } }, index) => (
          <div key={imageAlt} className="carousel-double__main-slide">
            <GatsbyImage
              image={image}
              alt={imageAlt}
              objectFit="contain"
              className="carousel-double__main-slide-img"
              isLazyLoading={index !== 0}
              fadeIn={false}
            />
          </div>
        ))}
      </Slider>

      <Slider
        className={classnames('carousel-double__secondary', {
          'carousel-double__secondary--with-arrow': slides.length > 6,
        })}
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        {...settings.settingsSecondary}
        {...sliderSettings}
      >
        {slides?.map(({ properties: { imageAlt, image } }, index) => (
          <div key={imageAlt} className="carousel-double__secondary-slide">
            <button type="button" className="carousel-double__secondary-thumbnail">
              <GatsbyImage
                image={image}
                alt={imageAlt}
                objectFit="contain"
                className="carousel-double__secondary-slide-img"
                isLazyLoading={index !== 0}
                fadeIn={false}
              />
            </button>
          </div>
        ))}
      </Slider>
    </div>
  ) : null;
};

export default PDCarouselDouble;
