import { UmbracoTags } from '@shared/types/umbraco/tags';
import { parseBoolean } from './parseBoolean';

const getBreadCrumbsTheme = (tags: UmbracoTags.IBaseTag[]): 'light' | 'dark' | 'gold' => {
  if (!tags?.length) return null;
  const familyTag = tags.filter((tag) => (parseBoolean(tag.isFamily) ? tag : null));
  const theme =
    familyTag?.length && familyTag[0]?.color?.label === 'quantum-silver'
      ? 'light'
      : familyTag[0]?.color?.label === 'ultimate-gold-gradient'
      ? 'gold'
      : 'dark';

  return theme;
};

export default getBreadCrumbsTheme;
