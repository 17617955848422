function transformProductTitle({
  subtitlePlaceholder = '',
  productSize = '',
  packagingType = '',
  productScent = '',
}: {
  subtitlePlaceholder?: string;
  productSize: string;
  packagingType?: string | null;
  productScent: string | null;
}): string {
  if (!subtitlePlaceholder) return '';

  return subtitlePlaceholder
    .replace(/%size%/i, `${productSize || ''}`)
    .replace(/%packagingType%/i, `${packagingType || ''}`)
    .replace(/%scent%/i, `${productScent || ''}`);
}

export default transformProductTitle;
