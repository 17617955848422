import React, { FC } from 'react';

import { BazaarVoiceSchemaProps } from './model';

const BazaarVoiceSchema: FC<BazaarVoiceSchemaProps> = ({
  productUrl,
  name,
  image,
  description,
  sku,
  brand,
}) => {
  const bvSchema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    '@id': productUrl,
    name,
    image,
    description,
    brand,
    sku,
  };

  const structuredData = JSON.stringify(bvSchema);

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} />;
};

export default BazaarVoiceSchema;
